@import url("https://fonts.googleapis.com/css2?family=Alexandria:wght@400;700&display=swap");

body {
  margin: 0;
  line-height: normal;
}

p {
  color: #666666;
}

:root {
  /* fonts */
  --h3: Alexandria;

  /* font sizes */
  --h3-size: 14px;
  --h1-size: 24px;
  --paragraph-1-size: 12px;
  --font-size-base: 16px;
  --h2-size: 20px;
  --font-size-3xs: 10px;

  /* Colors */
  --color-white1: #fff;
  --secondarycolor: #225043;
  --primarycolor: #c48a30;
  --paragraph: #666;
  --color-gray: rgba(255, 255, 255, 0.4);

  /* Gaps */
  --gap-7xs: 6px;
  --gap-12xs: 1px;
  --gap-8xs: 5px;
  --gap-3xs: 10px;

  /* Paddings */
  --padding-12xl: 31px;
  --padding-3xs: 10px;
  --padding-6xs: 7px;
  --padding-2xs: 11px;
  --padding-base: 16px;
  --padding-12xs: 1px;
  --padding-lgi: 19px;
  --padding-mini: 15px;
  --padding-smi: 13px;
  --padding-4xs: 9px;

  /* Border radiuses */
  --br-10xs: 3px;
  --br-7xs: 6px;
  --br-8xs: 5px;
  --br-3xs: 10px;
  --br-11xl: 30px;
  --br-5xs: 8px;
}

.image-8-icon {
  height: 23px;
  width: 20px;
  position: relative;
  object-fit: cover;
}

.image-row {
  border-radius: 5px;
  border: 1px solid #c4c4c4;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 3px 4px;
}

.image-7-icon {
  height: 20px;
  width: 20px;
  position: relative;
  object-fit: cover;
}

.image-row1 {
  height: 30px;
  width: 30px;
  border-radius: 5px;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.image-6-icon {
  height: 20px;
  width: 9.7px;
  position: relative;
  object-fit: cover;
}

.image-grid {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
}

.image-3-icon {
  height: 152px;
  width: 202px;
  position: relative;
  object-fit: cover;
}

.image-2-icon {
  align-self: stretch;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}

.image-wrapper {
  height: 124.5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 27.5px 0px 0px;
  box-sizing: border-box;
}

.content-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.header,
.image-3-parent {
  width: 339px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.header {
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  height: 156px;
  align-items: flex-end;
  justify-content: center;
  gap: 506px;
  background-image: url(images/header@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  font-size: var(--h3-size);
  color: #000;
}

.header-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  box-sizing: border-box;
  max-width: 100%;
}

.english {
  position: relative;
  line-height: 24px;
  color: var(--primarycolor);
  text-decoration: blink;
}

.en-language {
  width: 72px;
  border-radius: var(--br-10xs) var(--br-10xs) 0 0;
  background-color: var(--color-white1);
  border: 1px solid var(--paragraph);
  box-sizing: border-box;
  height: 34px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 0px;
  position: relative;
}

.exclude-icon {
  width: 18px;
  position: relative;
  height: 12.9px;
  max-width: max-content;
}

/* .youtube {
  width: 32px;
  border-radius: var(--br-10xs) var(--br-10xs) 0 0;
  background-color: var(--color-white1);
  border: 1px solid var(--paragraph);
  box-sizing: border-box;
  height: 34px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-6xs) var(--padding-2xs);
  border-bottom: 0px;
} */
.x-logo-icon {
  width: 18px;
  position: relative;
  height: 16px;
  max-width: max-content;
}

.social-icons-instagram {
  width: 30px;
  position: relative;
  border-radius: var(--br-7xs);
  height: 30px;
  max-width: max-content;
}

.vector-icon {
  width: 10px;
  position: relative;
  height: 17px;
  max-width: max-content;
}

.en-language-parent,
.facebook {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

/* .facebook {
  border-radius: var(--br-10xs) var(--br-10xs) 0 0;
  background-color: var(--color-white1);
  border: 1px solid var(--paragraph);
  box-sizing: border-box;
  height: 34px;
  padding: var(--padding-6xs) var(--padding-2xs);
  border-bottom: 0px;
} */
.en-language-parent {
  gap: var(--gap-7xs);
}

.div {
  align-self: stretch;
  position: relative;
  display: inline-block;
  min-width: 80px;
  color: #c48a30;
  ;
}

.menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 17px 0px 0px;
}

.menu-child {
  height: 32px;
  width: 1px;
  position: relative;
  border-right: 1px solid #fff;
  box-sizing: border-box;
}

.menu1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 9px 10px 10px;
}

.div1 {
  position: relative;
  display: inline-block;
  min-width: 119px;
}

.div2 {
  position: relative;
  display: inline-block;
  min-width: 74px;
}

.div3 {
  position: relative;
}

.div5 {
  position: relative;
  display: inline-block;
  min-width: 54px;
}

.navbar {
  align-self: stretch;
  background-color: #c48a30;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0px 20px;
  gap: 16px;
}

.main {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  max-width: 100%;
  text-align: left;
  font-size: 14px;
  color: #fff;
  font-family: Alexandria;
}

.image-4-icon {
  width: 416px;
  position: relative;
  max-height: 100%;
  object-fit: cover;
  max-width: 100%;
}

.p {
  margin: 0;
}

.div6 {
  align-self: stretch;
  height: 72px;
  position: relative;
  display: inline-block;
  flex-shrink: 0;
}

.slide-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 223px;
  max-width: 100%;
}

.slidenew {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 50px 0px;
  box-sizing: border-box;
  background-image: url("images/slide@3x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  max-width: 100%;
}

.slider {
  flex: 1;
  background-color: #225043;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 100%;
}

.sliderbanner {
  width: 100%;
  top: 207px;
  right: 0;
  left: 0;
  background-color: var(--secondarycolor);
  font-size: var(--font-size-base);
  color: var(--color-white1);
  margin-bottom: 1px;
}

.hero {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 0px 86px;
  box-sizing: border-box;
  max-width: 100%;
  z-index: unset;
  text-align: center;
  font-size: 16px;
  color: #fff;
  font-family: Alexandria;
}

.h3 {
  margin: 0;
  align-self: stretch;
  height: 90px;
  position: relative;
  font-size: inherit;
  line-height: 200%;
  font-weight: 400;
  font-family: inherit;
  display: inline-block;
  flex-shrink: 0;
}

.h2 {
  margin: 0;
  align-self: stretch;
  height: 31px;
  position: relative;
  font-size: 24px;
  line-height: 200%;
  font-weight: 700;
  font-family: inherit;
  display: inline-block;
  flex-shrink: 0;
}

.title {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
}

.button-content-child {
  width: 31px;
  height: 0px;
  position: relative;
  object-fit: contain;
}

.button-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 0px 0px;
}

.div7 {
  flex: 1;
  position: relative;
  font-size: 16px;
  line-height: 200%;
  font-family: Alexandria;
  color: #fff;
  text-align: center;
  display: inline-block;
  min-width: 70px;
}

.button-wrapper {
  cursor: pointer;
  border: none;
  padding: 0px 23px 0px 24px;
  background-color: #c48a30;
  border-radius: 40px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
}

.registration {
  align-self: stretch;
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 5px 65px;
}

.description {
  width: 334px;
  border-radius: 8px;
  background-color: #225043;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 100px 19px 62px;
  box-sizing: border-box;
  gap: 64px;
  max-width: 100%;
}

.mask {
  height: 254px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 8px 0px 0px;
  box-sizing: border-box;
}

.div8 {
  flex: 1;
  position: relative;
  font-size: 12px;
  line-height: 23px;
  color: #666;
  text-align: right;
  display: inline-block;
  min-width: 274px;
  max-width: 100%;
}

.description-parent {
  width: 1026px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 11px;
  max-width: 100%;
}

.info {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0px 22px 35px 20px;
  box-sizing: border-box;
  max-width: 100%;
  text-align: center;
  font-size: 20px;
  color: #fff;
  font-family: Alexandria;
}

.tahbeer-book-1-icon {
  height: 176.8px;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  object-fit: cover;
}

.tahbeer-book-1-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
}

.image-13-icon {
  width: 25px;
  height: 30.1px;
  position: relative;
  object-fit: cover;
}

.div9 {
  align-self: stretch;
  position: relative;
}

.frame-66variant3 {
  flex: 1;
  border-radius: 5px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 9px 0px 8.9px;
  gap: 5px;
}

.div10 {
  align-self: stretch;
  position: relative;
  font-size: 10px;
  font-family: Alexandria;
  color: #6b6b6b;
  text-align: center;
}

.frame-66variant31 {
  cursor: pointer;
  border: none;
  padding: 9px 0px 8.9px;
  background-color: #fff;
  flex: 1;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
}

.frame-66variant31:hover {
  background-color: #e6e6e6;
}

.language {
  width: 261px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 18px;
}

.div12 {
  flex: 1;
  position: relative;
  font-size: 14px;
  line-height: 23px;
  font-family: Alexandria;
  color: #fff;
  text-align: right;
}

.archive-link {
  cursor: pointer;
  border: none;
  padding: 5px 67px;
  background-color: #c48a30;
  align-self: stretch;
  border-radius: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.archive-link:hover {
  background-color: #ab7017;
}

.archive {
  width: 222px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 0px 0px;
  box-sizing: border-box;
  gap: 10px;
}

.h31 {
  margin: 0;
  flex: 1;
  position: relative;
  font-size: inherit;
  line-height: 24px;
  font-weight: 700;
  font-family: inherit;
}

.guide-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 9px;
}

.div13 {
  width: 124px;
  position: relative;
  line-height: 23px;
  display: inline-block;

}

.edition-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 12px;
  font-size: 16px;
  color: #666;
}

.guide {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  min-height: 116px;
  text-align: right;
  font-size: 20px;
  color: #c48a30;
}

.frame-parent {
  height: 124px;
  flex: 1;
  background-color: #c48a30;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 193px;
  box-sizing: border-box;
  max-width: 100%;
  gap: 20px;
}

.homepage-inner {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 0px 175px;
  box-sizing: border-box;
  max-width: 100%;
  text-align: center;
  font-size: 10px;
  color: #6b6b6b;
  font-family: Alexandria;
}

.h32 {
  margin: 0;
  flex: 1;
  position: relative;
  font-size: inherit;
  font-weight: 700;
  font-family: inherit;
}

.news-header {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px 663px;
}

.div14 {
  flex: 1;
  position: relative;
  line-height: 24px;
}

.datewrapper {
  background-color: #c48a30;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 0.5px 10px 0.5px 9px;
}

.div15 {
  position: relative;
  line-height: 24px;
}

.newsdetails {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 1px;
}

.newscontainer {
  align-self: stretch;
  background: linear-gradient(180deg, rgba(30, 30, 30, 0), #1e1e1e 90%);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 90px 13px 15px;
}

.newsdetails a {
  height: 100%;
  width: 100%;
  overflow: hidden;
  color: inherit !important;
}

.newsdetails .news-image {
  height: 100%;
  object-fit: cover;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  min-height: 100%;
  width: 100%;
}

.div17 {
  align-self: stretch;
  height: 74px;
  position: relative;
  line-height: 24px;
  display: inline-block;
  flex-shrink: 0;
}

.div20 {
  flex: 1;
  position: relative;
  font-size: 16px;
  line-height: 200%;
  font-family: Alexandria;
  color: #fff;
  text-align: center;
  z-index: unset;
}

.view-all {
  cursor: pointer;
  border: 1px solid #fff;
  padding: 3px 62px;
  background-color: #c48a30;
  height: 42px;
  border-radius: 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: unset;
}

.view-all:hover {
  background-color: #ab7017;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
}

.h33 {
  margin: 0;
  flex: 1;
  position: relative;
  font-size: inherit;
  font-weight: 700;
  font-family: inherit;
  display: inline-block;
  max-width: 100%;
}

.div21 {
  line-height: 23px;
  min-height: 95px;
}

.aboutusphototpng-icon {
  align-self: stretch;
  flex: 1;
  position: relative;
  border-radius: 10px;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
  z-index: unset;
}

.gallery-images {
  align-self: stretch;
  flex: 1;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  border: 1px solid #c48a30;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: unset;
}

.b {
  position: relative;
  line-height: 24px;
  z-index: unset;
}

.gallery-images-parent {
  align-self: stretch;
  width: 245px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  z-index: unset;
}

.aboutusphototpng-icon3 {
  align-self: stretch;
  flex: 1;
  position: relative;
  border-radius: 10px;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}

.aboutusphototpng-frame {
  align-self: stretch;
  flex: 1;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  border: 1px solid #c48a30;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.frame-div {
  align-self: stretch;
  width: 245px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.h34 {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  font-weight: 700;
  font-family: inherit;
}

.logo-items {
  height: 134px;
  border: 1px solid #c48a30;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px 17px;
}

.layer-2-icon {
  height: 100px;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  object-fit: cover;
}

.layer-1 {
  flex: 1;
  border: 1px solid #c48a30;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 14px;
  min-width: 118px;
  min-height: 132px;
  border-radius: var(--br-8xs);
}

.image-9-icon {
  height: 106px;
  width: 106px;
  position: relative;
  object-fit: cover;
}

.logo-items1 {
  border: 1px solid #c48a30;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 12px 21px;
}

.sponsor-logos {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px 13px;
  gap: 10px;
}

.sponsors-title {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 48px;
  max-width: 100%;
}

.div35 {
  flex: 1;
  position: relative;
}

.image-10-icon {
  width: 114px;
  position: relative;
  height: 101.8px;
  object-fit: cover;
}

.image-10-wrapper {
  width: 150px;
  border-radius: var(--br-8xs);
  border: 1px solid var(--primarycolor);
  box-sizing: border-box;
  height: 134px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.wrapper9 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.frame-parent5,
.image-9-wrapper {
  height: 134px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.image-9-wrapper {
  width: 150px;
  border-radius: var(--br-8xs);
  border: 1px solid var(--primarycolor);
  box-sizing: border-box;
  padding: 12px 0;
}

.frame-parent5 {
  align-self: center;
  flex-wrap: wrap;
  align-content: center;
  gap: 40px;
}

.frame-parent4 {
  flex: 1;
  height: 206px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.sponserguide {
  width: 100%;
  top: 2115px;
  right: 4px;
  left: -4px;
  height: 258px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.div36 {
  width: 122px;
  position: relative;
  line-height: 24px;
  display: inline-block;
  flex-shrink: 0;
  color: var(--primarycolor);
  text-decoration: blink;
}

.wrapper11 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

.social-icons {
  border-radius: 5px;
  border: 1px solid #c4c4c4;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2px 4px;
}

.social-icons2 {
  border-radius: 5px;
  border: 1px solid #c4c4c4;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 3px 9px;
}

.social {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 20px 0px;
  gap: 8px;
}

.div22 {
  position: relative;
  display: inline-block;
  min-width: 64px;
}

.link-items1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 21px;
}

.div24 {
  position: relative;
  display: inline-block;
  min-width: 69px;
}

.menu-links {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}

.div26 {
  position: relative;
  font-weight: 300;
  display: inline-block;
  min-width: 44px;
}

.div29 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
}

.more-links {
  height: 144px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}

.footer-menu {
  width: 296px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.image-12-icon {
  height: 161px;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  object-fit: cover;
}

.footer-content {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 14px 89px 2px;
  box-sizing: border-box;
  gap: 140px;
  max-width: 1440px;
}

.sectiontitle1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-lgi) 0;
}

.div30 {
  flex: 1;
  position: relative;
  line-height: 23px;
  display: inline-block;
  height: 35px;
}

.awardcategorytext {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
  max-width: 1024px;
  font-size: var(--paragraph-1-size);
  color: var(--paragraph);
}

.aboutusphototpng-icon {
  border-radius: 7px;
  height: auto;
  object-fit: cover;
  position: relative;
  width: 245px;
  margin-top: -1px !important;
}

.aboutusphototpng-wrapper {
  align-self: stretch;
  border-radius: var(--br-3xs);
  background-color: var(--color-white1);
  border: 2px solid var(--primarycolor);
  box-sizing: border-box;
  height: 114px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-12xs) 0;
  overflow: hidden;
}

.aboutusphototpng-wrapper:hover,
.image-9-wrapper:hover,
.layer-1:hover,
.image-10-wrapper:hover {
  box-shadow: 0 0 20px #c48a30;
}

.aboutusphototpng-wrapper:hover img,
.image-9-wrapper:hover img,
.layer-1:hover img,
.image-10-wrapper:hover img {
  transform: scale(1.1);
}

.div31 {
  align-self: stretch;
  height: 41px;
  position: relative;
  font-size: 14px;
  text-align: center;
  display: inline-block;
  flex-shrink: 0;
}

.award {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.awardcategorylist,
.awardcont {
  display: flex;
  align-items: center;
}

.awardcont {
  width: 245px;
  height: 170px;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
}

.awardcategorylist {
  align-self: stretch;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
  gap: 21px;
  font-size: var(--h3-size);
}

.awardcategory,
.awards {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.awardcategory {
  align-self: stretch;
  flex: 1;
  justify-content: space-between;
}

.awards {
  width: 100%;
  top: 1736px;
  right: 4px;
  left: -4px;
  background-color: #eaeaea;
  height: 379px;
  justify-content: flex-start;
  margin-top: 50px;
}

.frame-parent6,
.image-2-parent {
  display: flex;
  align-items: flex-end;
}

.image-2-parent {
  height: 197px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 30px;
  box-sizing: border-box;
}

.frame-parent6 {
  justify-content: space-between;
  min-width: 1050px;
  padding-bottom: 30px;
}

.frame-parent7,
.frame-parent9 {
  display: flex;
  justify-content: space-between;
}

.frame-parent9 {
  width: 117px;
  flex-direction: column;
  align-items: flex-end;
}

.div40 {
  width: 91px;
  position: relative;
  line-height: 24px;
  display: inline-block;
  flex-shrink: 0;
  color: var(--primarycolor);
  text-decoration: blink;
}

.wrapper14 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

.div41 {
  width: 104px;
  position: relative;
  line-height: 24px;
  display: inline-block;
  flex-shrink: 0;
  color: var(--primarycolor);
  text-decoration: blink;
}

.div43 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: var(--padding-3xs) 0;
}

.frame-parent7 {
  width: 296px;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 70px;
}

.image-2-icon1,
.image-3-icon1 {
  width: 124px;
  position: relative;
  height: 88px;
  object-fit: cover;
}

.image-3-icon1 {
  width: 145px;
  height: 109px;
}

.frame-parent8 {
  width: 144px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}

.contactus {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

.footer-child {
  width: 1050px;
  position: relative;
  object-fit: contain;
}

.footer {
  width: 100%;
  top: 2411px;
  right: 0;
  left: 0;
  background-color: var(--secondarycolor);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: right;
  font-size: var(--h3-size);
}

.homepage {
  width: 100%;
  position: relative;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  line-height: normal;
  letter-spacing: normal;
}

@media screen and (max-width: 1050px) {
  .footer-content {
    gap: 70px;
    flex-wrap: wrap;
    padding-left: 44px;
    padding-right: 44px;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 750px) {
  .frame-parent {
    padding-left: 96px;
    padding-right: 96px;
    box-sizing: border-box;
  }

  .footer-content {
    gap: 35px;
    padding-left: 22px;
    padding-right: 22px;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 450px) {
  .hero {
    padding-bottom: 56px;
    box-sizing: border-box;
  }

  .h3 {
    font-size: 16px;
    line-height: 32px;
  }

  .h2 {
    font-size: 19px;
    line-height: 38px;
  }

  .registration {
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
  }

  .description {
    gap: 32px;
    padding-top: 65px;
    padding-bottom: 40px;
    box-sizing: border-box;
  }

  .h31 {
    font-size: 16px;
    line-height: 19px;
  }

  .frame-parent {
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
  }

  .h32 {
    font-size: 16px;
  }


  .view-all {
    width: calc(100% - 40px);
  }

  .h33 {
    font-size: 16px;
  }

  .h34 {
    font-size: 16px;
  }

  .sponsor-logos {
    flex-wrap: wrap;
  }
}

.aboutaward {
  width: 100%;
  top: 532px;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 110px;
  margin-top: 30px;
}

.rectangle-parent {
  width: 326px;
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-6xs) 0;
  gap: var(--gap-3xs);
}

.frame-child1 {
  align-self: stretch;
  position: relative;
  background-color: transparent;
  height: 32px;
}

.frame-wrapper3,
.rectangle-parent {
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
}

.frame-wrapper3 {
  align-self: stretch;
  border-radius: var(--br-5xs);
  height: 359px;
  flex-direction: row;
  justify-content: center;
  padding: var(--padding-12xl) var(--padding-lgi);
  background-image: url(images/frame-21@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.div133 {
  align-self: stretch;
  position: relative;
  line-height: 23px;
  display: inline-block;
  height: 100%;
  flex-shrink: 0;
  overflow: auto;
}

.groupabout {
  width: 598px;
  height: 359px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 13px;
  text-align: right;
}

[dir="ltr"] .groupabout {
  text-align: left;
}

.mask-group-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 241px;
  height: 254px;
  object-fit: cover;
}

.mask-group-parent {
  align-self: stretch;
  position: relative;
  height: 310px;
  font-size: var(--paragraph-1-size);
  color: var(--paragraph);
}

.wrapper5 {
  position: absolute;
  top: 0;
  right: 3px;
  width: 335px;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
}

[dir="ltr"] .wrapper5 {
  left: 0;
}

[dir="ltr"] .mask-group-icon {
  right: 0;
  left: auto;
  top: 5px;
}

.frame-group {
  flex: 1;
  height: 194px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 64px;
}

.parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-8xs);
}

.p1,
.p2 {
  margin: 0;
  line-height: 156%;
  color: #c48a30;
}

.p2 {
  font-size: var(--h2-size);
  line-height: 200%;
  color: var(--color-white1);
}

.b,
.parent {
  align-self: stretch;
}

.b {
  position: relative;
  line-height: 200%;
  display: inline-block;
  color: var(--color-white1);
  height: 31px;
  flex-shrink: 0;
}

.frame-child2,
.wrapper4 {
  border-radius: var(--br-11xl);
  height: 42px;
}

.wrapper4 {
  width: 232px;
  background-color: var(--primarycolor);
  border: 1px solid var(--color-white1);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-3xs);
  font-size: var(--font-size-base);
  color: var(--color-white1);
  cursor: pointer;
}

.wrapper4:hover {
  background-color: #ab7017;
}

.frame-child2 {
  width: 154px;
  position: relative;
}

.div11 {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: var(--color-white1) !important;
  text-decoration: none;
}

.guide-language {
  width: 80px;
  border-radius: 10px;
  background-color: var(--color-gray);
  border: 0.5px solid var(--color-white1);
  box-sizing: border-box;
  flex-direction: column;
  padding: 20px 0 2px;
  gap: var(--gap-8xs);
  position: relative;
  overflow: hidden;
}

.guide-language:hover {
  background-color: #ffffff;
  border-color: #955a00;
  cursor: pointer;
}

.guide-language,
.guide-language-parent,
.manual-inner {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.guide-language-parent {
  flex-direction: row;
  gap: 18px;
}

.manual-inner {
  flex-direction: column;
}

.wrapper6,
.wrapper7 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.wrapper6 {
  width: 134px;
  padding: var(--padding-base) 0;
  box-sizing: border-box;
}

.wrapper7 {

  height: 66px;
  font-size: var(--h3-size);
}

.frame-parent2 {
  width: 155px;
  height: 116px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: right;
  font-size: var(--h2-size);
  color: var(--color-white1);
}
[dir='ltr'] .frame-parent2{
  text-align: left ;
}

.manual {
  width: 100%;
  top: 998px;
  right: 0;
  left: 0;
  box-shadow: 0 9px 14.8px rgba(0, 0, 0, 0.1);
  background-color: var(--primarycolor);
  height: 122px;
  justify-content: center;
  padding: 0 202px;
  box-sizing: border-box;
  gap: 40px;
  font-size: var(--font-size-3xs);
  color: var(--secondarycolor);
}

.datewrapper,
.manual,
.sectiontitle {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.sectiontitle {
  align-self: stretch;
  justify-content: center;
  color: var(--primarycolor);
}

.tahbeer-book-1-icon,
.tahbeer-book-1-icon1 {
  width: 130px;
  position: relative;
  height: 176.8px;
  object-fit: cover;
}

.tahbeer-book-1-icon1 {
  width: 180px;
  /* height: 163.2px; */
}

[dir="ltr"] .tahbeer-book-1-icon1 {
  transform: scaleX(-1);
}

.tahbeer-book-1-container {
  filter: drop-shadow(-7px 0 5.8px rgba(0, 0, 0, 0.6));
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-3xs);
}

.tahbeer-book-1-icon2 {
  width: 110px;
  position: relative;
  height: 149.6px;
  object-fit: cover;
}

.tahbeer-book-1-frame {
  filter: drop-shadow(-5px 0 9.8px rgba(0, 0, 0, 0.77));
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-3xs);
}

.frame-parent1 {
  width: 234px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}

.seprater-child {
  width: 1px;
  position: relative;
  border-right: 1px solid var(--color-white1);
  box-sizing: border-box;
  height: 32px;
}

.seprater {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-3xs);
}

.divlanguage {
  align-self: stretch;
  position: relative;
  font-size: 12px;
  color:#fff
}

.newsdetails {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-12xs);
  position: relative;
  height: 100%;
}

.news1,
.news2,
.news3,
.news4,
.newscontainer {
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  overflow: hidden;
}

.newscontainer {
  width: 242px;
  background: linear-gradient(180deg, rgba(30, 30, 30, 0), #1e1e1e 90%);
  height: 293px;
  padding: 0;
  box-sizing: border-box;
  margin: 0 auto;
}

.news1,
.news2,
.news3,
.news4 {
  height: 293px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.newslist {
  align-self: stretch;
  height: 293px;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
  gap: 24px;
  text-align: right;
  font-size: var(--h3-size);
}

.news,
.newslist,
.wrapper8 {
  display: flex;
  align-items: center;
}

.wrapper8 {
  width: 318px;
  border-radius: var(--br-11xl);
  background-color: var(--primarycolor);
  border: 1px solid var(--color-white1);
  box-sizing: border-box;
  height: 42px;
  flex-direction: row;
  justify-content: center;
  font-size: var(--font-size-base);
}

.wrapper8:hover {
  background-color: #ab7017;
}

.news {
  width: calc(100% - 12px);
  top: 1209px;
  right: 10px;
  left: 2px;
  flex-direction: column;
  justify-content: flex-start;
  gap: 37px;
  color: var(--color-white1);
  padding-top: 40px;
}

.tahbeerhomepage {
  width: 100%;
  position: relative;
  background-color: var(--color-white1);
  overflow: hidden;
  text-align: center;
  /* font-size: var(--h1-size);*/
  color: var(--primarycolor);
  font-family: var(--h3);
}


.en-language-parent div a,
.en-language-parent .en-language,
.wrapper4,
.guide-language,
.wrapper8,
.aboutusphototpng-wrapper,
.aboutusphototpng-wrapper img,
.image-9-wrapper,
.image-9-wrapper img,
.layer-1 img,
.image-10-wrapper img,
.hamburger-wrapper,
.menu {
  transition: color .15s ease-in-out, background-image .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, transform .15s ease-in-out
}

.en-language-parent .en-language button {
  width: 100%;
  height: 33px;
  border-radius: 3px;
  text-align: center;
}

.en-language-parent .en-language button:hover {
  background-color: #ffefd5;
  border-color: #9b640f;
}

.en-language-parent div:hover {
  cursor: pointer;
}

.scrollbar {
  height: 300px;
  width: 50%;
  overflow: auto;
  padding: 0 10px;
}

/*       ScrollBar 1        */

#scrollbar1::-webkit-scrollbar {
  width: 10px;
}

#scrollbar1::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: #e7e7e7;
  border: 1px solid #cacaca;
}

#scrollbar1::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #c48a30;
}

.frame-parent2 .english,
.frame-parent2 .english p {
  color: #ffffff;
}

.manual {
  margin: 20px 0px;
}

.newsCaption {
  position: absolute;
  bottom: 0;
  right: 0;
  text-align: right;
  width: 100%;
  background: linear-gradient(180deg, rgba(30, 30, 30, 0), #1e1e1e 70%);
  min-height: 180px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 0 10px;
}

.div45 {
  padding: 15px 0;
}

.bg-secound-green {
  background-color: #225043;
}

.text-xl {
  font-size: 1rem;
}

.tahbeerhomepage a {
  color: var(--primarycolor);
}

@media (min-width: 1200px) {

  .h1,
  h1 {
    font-size: 1.8rem !important;
  }
}

.breadcrumbWrapper {
  /* background-color: #f3f3f3 !important; */
  padding: 20px 0px;
}

.news-items {
  display: flex;
  flex-wrap: wrap;
  gap: 100px;
}

.accordion-button:focus {
  z-index: 3;
  outline: 0;
  box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
  color: #fff !important;
  background-color: #c48a30 !important;
  box-shadow: none !important;
  ;
}

.form-submit-button {
  background: #c48a30 !important;
  color: white;
  height: 44px;
  width: 106px;
  text-shadow: none;
}

.show {
  visibility: visible;
  display: block !important;
}

.accordion-body {
  text-align: left;
  font-size: .8rem;
}

[dir="rtl"] .accordion-body {
  text-align: right;
}

.frame-parent7 a {
  text-align: left;
}

[dir="rtl"] .frame-parent7 a {
  text-align: right;
}

.carousel-item img {
  margin: 0 auto;
}

.newsRightSide {
  flex: 1 1 calc(50% - 40px);
}

.newsLeftSide {
  height: 100%;
  max-height: 100%;
  background-color: #f7f7f7;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: calc(50% - 20px);
  padding: 10px;
  text-align: right;
  display: flex;
  flex-direction: column;
  min-height: 398px;
}

[dir="ltr"] .newsLeftSide {
  text-align: left;
}

.newsLeftSide h3 {
  color: #c48a30;
  font-size: 1.2rem;
  line-height: 23px;
  min-height: 64px;
}

.newsLeftSide p {
  color: #585858;
  font-size: 0.9rem !important;
  line-height: 18px;
  max-height: 250px;
  height: calc(100% - 25px);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
}

.newsLeftSide p span {
  font-size: 0.9rem !important;
  font-weight: normal !important;
}


.newsLeftSide a {
  color: #fff;
  font-size: 12px;
  display: inline-flex;
  text-decoration: none;
}

.news-details {
  margin-top: 10px;
}

.news-details .news-image {
  float: left;
  margin-right: 40px;
  max-width: 660px;
  margin-bottom: 40px;
}


[dir="ltr"] .news-details .news-image {
  float: right;
  margin-right: 0;
  max-width: 660px;
  margin-bottom: 40px;
  margin-left: 40px;
}

[dir="ltr"] .news-details p {
  text-align: start;
}

.news-details h6 {
  text-align: right;
  line-height: inherit;
}

.news-details p {
  font-size: 0.8rem;
  line-height: 22px;
  text-align: right;
}

.pageTitle {
  margin: 20px 0px 30px 0px;
}

.en-language-parent div a {
  width: 35px;
  position: relative;
  height: 35PX;
  display: block;
  background-repeat: no-repeat;
  background-position: center center;
}

.whatsapp a {
  background-image: url(images/whatsapp_grey.svg);
}

.whatsapp a:hover {
  background-image: url(images/whatsapp_green.svg);
  background-color: transparent;
}

.youtube a {
  background-image: url(images/youtube.svg);
}

.youtube a:hover {
  background-image: url(images/youtube-over.svg);
  background-color: transparent;
}

.twitter a {
  background-image: url(images/x.svg);
}

.twitter a:hover {
  background-image: url(images/X-over.svg);
  background-color: transparent;
}

.facebook a {
  background-image: url(images/facebook.svg);
}

.facebook a:hover {
  background-image: url(images/facebook-over.svg);
  background-color: transparent;
}

.instagram a {
  background-image: url(images/instagram_grey.svg);
}

.instagram a:hover {
  background-image: url(images/instagram-over.svg);
  background-color: transparent;
}

.tiktok a {
  background-image: url(images/tiktok.svg);
}

.tiktok a:hover {
  background-image: url(images/tiktok-over.svg);
  background-color: transparent;
}

.homeAlert #AlertTextDiv {
  background-color: #c48a30 !important;
  padding: 10px 20px;
  text-align: left;
}

[dir="rtl"] .homeAlert #AlertTextDiv {
  text-align: right;
}

.whatsapp div:hover {
  background-color: transparent;
}

.folder {
  display: flex;
  align-items: center;
}

.folder .folderTitle {
  flex: 1;
}

.folder-wrap .slick-dots {
  bottom: -10px;
}

[dir="rtl"] .accordion-header button {
  text-align: right;
  font-size: 0.9rem;
}

.resWrap {
  padding: 20px;
}

.fm {
  font-size: 0.8rem;
}

.questionRadio .hhStatement,
.questionRadio .radioWrapper {
  color: rgb(55 65 81);
  text-align: left;
}

[dir="rtl"] .questionRadio .hhStatement,
[dir="rtl"] .questionRadio .radioWrapper {
  text-align: right;
}

.questionRadio .radioWrapper .form-check {
  margin-bottom: 1rem;
}

.questionRadio .radioWrapper .form-check-input:checked {
  background-color: #c48a30;
  border-color: #c48a30;
}

.form-check-input:focus {
  border-color: #c48a30 !important;
  box-shadow: 0 0 0 .25rem rgba(196, 138, 48, .25) !important;
}

.quesRadioButtons .btn-primary {
  background-color: #c48a30;
  border-color: #875303;
}

.quesRadioButtons .btn-primary:disabled {
  background-color: #c48a30;
  border-color: #875303;
  opacity: 0.5;
}

.quesRadioButtons .btn-primary:hover {
  background-color: #bd780f;
  border-color: #875303;
}

.quesRadioButtons .btn-primary:focus-visible {
  box-shadow: 0 0 0 0.25rem rgba(196, 138, 48, .5);
  background-color: #bd780f;
  border-color: #875303;
}

.ril__captionContent {
  width: 100%;
  text-align: center;
}

.newsDate {
  color: #225043;
  padding: 0;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: fit-content;
  margin-bottom: 10px;
  min-width: 100px;
}

#google_translate_element {
  position: absolute;
  top: -1px;
  left: -166px;
  width: 163px;
}

#google_translate_element .goog-te-gadget-simple {
  white-space: nowrap;
  display: flex;
  padding: 6.5px;
}

#google_translate_element .goog-te-gadget-simple span a {
  display: flex;
  align-items: center;
  height: auto;
  width: auto;
}

.news-item {
  align-items: flex-start;
  flex-basis: calc(50% - 70px);
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
  padding: 10px;
  background: #f7f7f7;
  border-radius: 9px;
  BORDER: 1PX SOLID #c5c5c5;
}

.slick-prev:before,
.slick-next:before {
  font-size: 30px !important;
  color: #c48a30 !important;
}

input::placeholder {
  color: #000;
  opacity: 0.5;
}

select:has(option:checked:first-child) {
  color: #000;
  opacity: 0.5;
}

option {
  color: #374151;
  text-transform: none;
}

.carousel-indicators [data-bs-target] {
  border-top: 5px solid transparent !important;
}

.passwordVisibility {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  right: 10px;
}

[dir="rtl"] .passwordVisibility
 {
  right: auto;
  left: 10px;
}

.login-form {
  max-width: 400px;
  margin: 0 auto;
  padding-bottom: 20px;
}

.nextBtn {
  background-color: #c48a30;
  color: #fff;
  padding: 0.25rem 0.5rem;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  min-width: 180px;
}

.nextBtn:hover {
  background-color: #b67008;
}

.tC {
  padding: 0 124px;
}

.btnPrev {
  min-width: 180px;
}

.btnContainer {
  clear: both;
  padding: 0 127px;
}

.centerContainer {
  padding: 0 127px;
}

.error {
  color: red;
  font-size: 0.8rem;
}

.formSettings select {
  height: 50px;
}

.react-datepicker-wrapper input {
  color: #212529;
}

[dir="rtl"] .float-left {
  float: right !important;
}

[dir="rtl"] .float-right {
  float: left !important;
}

.radioOpt {
  padding: 0 15px;
}

.radioOpt input {
  margin-left: 8px;
}

[dir="ltr"] .radioOpt input {
  margin-left: 0;
  margin-right: 8px;
}

.readQuran {
  text-align: right;
  line-height: 2rem;
  margin-bottom: 0rem;
  margin-top: 2px;
}

[dir="ltr"] .readQuran {
  text-align: left;
  line-height: 2rem;
  margin-bottom: 0rem;
  margin-top: 2px;
}

.scrollDiv {
  border: 1px solid #ccc;
  padding: 5px;
  width: 100%;
  height: 470px;
  overflow-y: scroll;
  background: #f9f9f9;
}



.startRecordBtn {
  background: rgb(1, 227, 61);
  background: linear-gradient(180deg, rgba(1, 227, 61, 1) 0%, rgba(37, 105, 0, 1) 100%);
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
}

.Rec {
  animation-name: pulseGreen;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes pulseGreen {
  0% {
    box-shadow: 0px 0px 2px 0px rgba(18, 222, 0, 0.3);
  }

  65% {
    box-shadow: 0px 0px 2px 8px rgba(28, 105, 0, 0.3);
  }

  90% {
    box-shadow: 0px 0px 2px 8px rgba(255, 255, 255, 0);
  }
}

.takeOtherRecordBtn {
  background: rgb(1, 227, 61);
  background: linear-gradient(180deg, rgba(1, 227, 61, 1) 0%, rgba(37, 105, 0, 1) 100%);
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
}

.Recanother {
  animation-name: pulseGreen;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes pulseGreen {
  0% {
    box-shadow: 0px 0px 2px 0px rgba(18, 222, 0, 0.3);
  }

  65% {
    box-shadow: 0px 0px 2px 8px rgba(28, 105, 0, 0.3);
  }

  90% {
    box-shadow: 0px 0px 2px 8px rgba(255, 255, 255, 0);
  }
}


.stopRecordBtn {
  background: rgb(255, 0, 0);
  background: linear-gradient(180deg, rgba(255, 0, 0, 0.9948354341736695) 0%, rgba(153, 30, 0, 1) 100%);
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
}

.RecRed {
  animation-name: pulseRed;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes pulseRed {
  0% {
    box-shadow: 0px 0px 2px 0px rgba(255, 0, 0, 0.9);
  }

  65% {
    box-shadow: 0px 0px 2px 8px rgba(255, 0, 0, 0.5);
  }

  90% {
    box-shadow: 0px 0px 2px 8px rgba(255, 255, 255, 0.6);
  }
}

.labelW {
  display: inline-block;
  min-width: 150px;
}

.filterWrap {
  min-width: 600px;
}

.filterWrap input,
.filterWrap select {
  border: 1px solid rgb(204, 204, 204);
  color: #666;
  border-radius: 5px;
  padding: 5px;
  opacity: 1;
}

.filterWrap input:focus,
.filterWrap select:focus {
  outline: none;
}

.filterWrap select {
  min-width: 205px;
}

.regButton {
  clear: both;
}

.regButton button {
  float: right;
  min-width: auto !important;
  padding: 10px 30px !important;
}


[dir="rtl"] .regButton button {
  float: left !important;
  min-width: auto !important;
}

.userSummary {
  color: #666666;
  text-align: left !important;
}

[dir="rtl"] .userSummary {
  text-align: right !important;
}
.imageBackground{
 
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}  

.imageBackground::before{
  content: "";
  background-image: linear-gradient(transparent, #000 120%);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}




















































@media only screen and (max-device-width: 1024px) {
  .header {
    gap: 335px;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding: 8px 20px !important;
  }

  .navbar-expand-lg .navbar-nav .nav-item {
    border-left: 1px solid #fbfbfb;
    position: relative;
  }

  [dir="rtl"] .navbar-expand-lg .navbar-nav .nav-item {
    border-left: 0;
    border-right: 1px solid #fbfbfb;
  }


  .news-items {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
  }

  .breadcrumbWrapper {
    padding: 20px 0 10px 0px;
    border-bottom: 1px solid #e1e1e1;
    margin: 0 0 30px 0;
  }

  .aboutaward,
  .groupabout {
    height: auto;
  }

  .mask-group-icon {
    float: right;
    position: relative;
  }

  [dir="rtl"] .mask-group-icon {
    float: left;
  }

  .wrapper5 {
    position: relative;
    width: auto;
    height: auto;
    display: inline;
  }

  .div133 {
    display: inline;
  }

  .mask-group-parent {
    height: auto;
  }

  .carousel-indicators [data-bs-target] {
    border-top: 6px solid transparent !important;
  }

  .aboutaward {
    gap: 40px;
  }

  .manual {
    padding: 0 20px;
  }

  .tahbeer-book-1-icon1 {
    height: 160px;
  }

  .frame-parent2 {
    width: 220px;
  }

  .slick-prev,
  .slick-next {
    width: 35px;
  }

  .awards,
  .div30 {
    height: auto;
  }

  .div30 {
    padding: 0 20px 20px;
  }

  .frame-parent6 {
    min-width: 850px;
  }

  .excBtn,
  .createEntity {
    float: left !important;
  }

  [dir="rtl"] .excBtn,
  [dir="rtl"] .createEntity {
    float: right !important;
  }

  .mListPage {
    justify-content: flex-start !important;
  }

  #google_translate_element {
    top: -45px;
    left: -1px;
  }


}

/* 1024 end here */




@media only screen and (max-device-width: 820px) {

  .header {
    gap: inherit;
    flex-direction: column-reverse;
    height: auto;
  }

  .header .content-row {
    align-items: center;
    align-self: flex-end;
  }

  .header>div {
    flex: 1;
    align-self: flex-start;
    margin-left: 20px;
  }

  [dir="ltr"] .header>div {
    flex: 1;
  }

  .en-language-parent {
    align-self: flex-start;
  }

  [dir="ltr"] .en-language-parent {
    align-self: flex-start;
    margin-left: 20px;
  }

  .image-3-icon {
    max-width: 150px;
    height: auto;
  }

  .image-wrapper {
    height: auto;
    padding: 0;
    max-width: 120px;
  }

  .menu {
    margin: 0;
    right: 0;
    max-height: fit-content;
    max-width: 80%;
    position: absolute;
    top: 42px;
    width: 100%;
    background-color: #c48a30;
    padding: 12px;
    text-align: right;
  }

  [dir="ltr"] .menu {
    text-align: left;
    right: auto;
    left: 0;
  }

  .aboutaward {
    flex-direction: column;
    gap: inherit;
  }

  .groupabout {
    width: auto;
    height: auto;
  }

  .groupabout .div {
    text-align: left;
    padding-top: 0;
  }

  [dir="rtl"] .groupabout .div {
    text-align: right;
    padding-top: 0;
  }

  .mask-group-parent {
    flex-direction: row;
    display: flex;
    height: auto;
    width: auto;
  }

  .mask-group-parent .mask-group-icon {
    position: relative;
  }

  .groupabout .wrapper5 {
    height: auto;
    position: relative;
    width: 485px;
    margin-left: 20px;
  }

  [dir="rtl"] .groupabout .wrapper5 {
    margin-left: 0;
    margin-right: 20px;
  }

  .manual {
    height: auto;
    gap: 5px;
    padding: 20px 10px 20px 0;
  }

  [dir="ltr"] .manual {
    padding: 20px 0px 20px 10px;
  }

  .frame-parent2 {
    width: auto;
    height: auto;
    align-items: flex-start;
    min-width: 120px;
  }

  [dir="ltr"] .frame-parent2 {
    align-items: flex-start;
  }

  .wrapper6 {
    width: auto;
  }

  .wrapper6 b {
    font-size: 16px;
  }

  .english {
    font-size: 12px;
    text-align: right;
  }

  [dir="ltr"] .english {
    text-align: left;
  }

  .manual-inner {
    width: auto;
  }

  .guide-language-parent {
    gap: 6px;
    flex-wrap: wrap;
    width: 480px;
  }

  .frame-parent1 {
    width: auto;
    flex: 1;
  }

  .tahbeer-book-1-icon1 {
    width: 100%;
    height: 120px;
  }

  .tahbeer-book-1-container {
    padding: 0px;
  }

  .div30 {
    height: auto;
    padding: 10px;
  }

  .awards {
    height: auto;
    background-color: #ffffff;
  }

  .awardcategorytext {
    padding-bottom: 20px;
  }

  .awardcategorylist {
    gap: 6px;
    padding: 0 10px;
  }

  .sectiontitle1 {
    padding-bottom: 0;
  }

  .sponserguide {
    height: auto;
    padding: 20px;
  }

  .frame-parent4 {
    height: auto;
  }

  .wrapper9 {
    padding-bottom: 20px;
  }

  .frame-parent5 {
    height: auto;
    gap: 20px;
  }

  .frame-parent6 {
    min-width: auto;
    flex-direction: row;
    width: 100%;
    align-items: flex-start;
    padding: 20px;
    flex-wrap: wrap;
  }

  .image-2-parent {
    padding-top: 0;
    width: auto;
    display: flex;
    flex-direction: row;
    height: auto;
  }

  .footer .en-language-parent {
    margin: 0 auto;
    padding-top: 20px;
  }

  .frame-parent7 {
    width: 420px;
    justify-content: center;
    margin-top: 0px;
  }

  [dir="rtl"] .frame-parent7 a {
    text-align: right;
  }

  .layer-1 {
    width: 150px;
    flex: none;
  }

  .news-items {
    flex-direction: column;
    gap: 20px;
  }

  .news-items .news-item a {
    flex-direction: column;
  }

  .newsRightSide img {
    min-height: auto;
    max-height: auto;
  }

  .newsLeftSide h3 {
    min-height: auto;
  }

  .resWrap {
    width: 100%;
    margin: 10px;
    border-radius: 10px;
    padding: 0 !important;
    overflow: hidden;
  }

  .news-details .news-image {
    float: none;
    margin-right: 0;
    max-width: inherit;
    margin-bottom: 20px;
    margin-left: 0;
  }

  .resWrap h1 {
    font-size: 1.4rem;
    margin: 20px 0 10px 0;
  }

  .resWrap .stage {
    padding: 0;
  }

  .resWrap .folder-wrap svg {
    font-size: 40px;
  }

  .folder .folderTitle {
    text-align: left;
    padding-left: 20px;
  }

  [dir="rtl"] .folder .folderTitle {
    flex: 1 1;
    text-align: right;
    padding-left: 0px;
    padding-right: 20px;
  }

  [dir="rtl"] ol,
  [dir="rtl"] ul {
    padding-right: 2rem;
    padding-left: 0rem !important;
  }

  .breadcrumbWrapper {
    padding: 20px !important;
  }

  .homeAlert span,
  #AlertTextDiv span {
    text-align: left;
  }

  [dir="rtl"] .homeAlert span,
  [dir="rtl"] #AlertTextDiv span {
    text-align: right;
  }

  .socialMediaWrap {
    margin-top: 30px !important;
  }

  .socialMediaWrap .col-md-2 {
    width: 33% !important;
  }

  .hamburger-menu {
    margin-left: 10px;
  }

  [dir="rtl"] .hamburger-menu {
    margin-right: 10px;
  }

  .navbar-expand-lg .navbar-nav .nav-item {
    border: none !important;
  }

  #google_translate_element {
    top: -44px;
    left: -1px;
  }

  .tC {
    padding: 0;
  }

  .btnContainer {
    padding: 0;
  }

  .centerContainer {
    padding: 0 15px;
  }

  .tabletRow {
    flex-direction: column;
  }

  .tabletRow div {
    width: 100%;
  }

  .tabletRow div:last-child {
    padding-top: 0 !important;
  }

  .tabletRow .userSummary {
    padding: 0rem 1.5rem !important;
  }

  .tabletRow .userSummary div:first-child {
    margin-top: 0px !important;
  }

  .questionRadio {
    width: 100%;
  }

  #AlertTextDiv {
    padding: 5px 10px;
  }

  .formSettings .md\:flex-row {
    flex-direction: column;
  }

  .formSettings .md\:w-1\/3 {
    width: 100%;
  }

  .regEntity .md\:w-1\/3 {
    width: 45%;
  }

  .mTableList {
    width: 95%;
  }

  .mTableList.p-4 {
    padding: 10px !important;
  }

  .mTableList table {
    min-width: 800px;
    margin-right: 2px;
  }

  [dir="rtl"] .mTableList table {
    margin-right: 0;
    margin-left: 2px;
  }

  .excBtn,
  .createEntity {
    float: left !important;
  }

  [dir="rtl"] .excBtn,
  [dir="rtl"] .createEntity {
    float: right !important;
  }



}

/* 820 end here*/



@media only screen and (max-device-width: 600px) {

  .header {
    gap: inherit;
    flex-direction: column-reverse;
    height: auto;
  }

  .header .content-row {
    align-items: center;
    align-self: flex-end;
  }

  .header>div {
    flex: 1;
    align-self: flex-start;
  }

  [dir="ltr"] .header>div {
    flex: 1;
  }

  .en-language-parent {
    align-self: flex-start;
    margin-left: 10px;
  }

  [dir="ltr"] .en-language-parent {
    align-self: flex-start;
  }

  .image-3-icon {
    max-width: 120px;
    height: auto;
  }

  .image-wrapper {
    height: auto;
    padding: 0;
    max-width: 90px;
  }

  .menu {
    margin: 0;
    right: 0;
    max-height: fit-content;
    max-width: 80%;
    position: absolute;
    top: 42px;
    width: 100%;
    background-color: #c48a30;
    padding: 12px;
    text-align: right;
  }

  [dir="ltr"] .menu {
    text-align: left;
    right: auto;
    left: 0;
  }

  .aboutaward {
    flex-direction: column;
    gap: inherit;
  }

  .groupabout {
    width: auto;
    height: auto;
  }

  .groupabout .div {
    text-align: center;
    padding-top: 20px;
  }

  .mask-group-parent {
    flex-direction: column;
    display: flex;
    height: auto;
    width: auto;
    padding: 0 20px;
  }

  .mask-group-parent .mask-group-icon {
    position: relative;
    height: auto;
    margin: 0 auto;
  }

  .groupabout .wrapper5 {
    height: auto;
    position: relative;
    margin: 0 auto !important;
    width: auto;
  }

  .manual {
    height: auto;
    gap: 5px;
    padding: 20px 10px 20px 0;
    flex-direction: column;
  }

  [dir="ltr"] .manual {
    padding: 20px 0px 20px 10px;
  }

  .frame-parent2 {
    width: auto;
    height: auto;
    align-items: center;
  }

  [dir="ltr"] .frame-parent2 {
    align-items: flex-start;
  }

  .wrapper6 {
    width: auto;
  }

  .wrapper6 b {
    font-size: 16px;
  }

  .english {
    font-size: 12px;
    text-align: right;
  }

  [dir="ltr"] .english {
    text-align: left;
  }

  .manual-inner {
    width: auto;
  }

  .guide-language-parent {
    gap: 6px;
    flex-wrap: wrap;
    width: 380px;
    justify-content: center;
  }

  .frame-parent1 {
    width: auto;
    flex: 1;
  }

  .tahbeer-book-1-icon1 {
    width: 100%;
    height: 130px;
  }

  .div30 {
    height: auto;
    padding: 10px;
  }

  .awards {
    height: auto;
    background-color: #ffffff;
  }

  .awardcategorytext {
    padding-bottom: 20px;
  }

  .awardcategorylist {
    gap: 10px;
  }

  .sectiontitle1 {
    padding-bottom: 0;
  }

  .sponserguide {
    height: auto;
    padding: 20px;
  }

  .frame-parent4 {
    height: auto;
  }

  .wrapper9 {
    padding-bottom: 20px;
  }

  .frame-parent5 {
    height: auto;
    gap: 20px;
  }

  .frame-parent6 {
    min-width: auto;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    padding: 20px;
  }

  .image-2-parent {
    padding-top: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    height: auto;
  }

  .footer .en-language-parent {
    margin: 0 auto;
    padding-top: 20px;
  }

  [dir="rtl"] .frame-parent7 a {
    text-align: right;
  }

  .layer-1 {
    width: 150px;
    flex: none;
  }

  .news-items {
    flex-direction: column;
    gap: 20px;
  }

  .news-items .news-item a {
    flex-direction: column;
  }

  .newsRightSide img {
    min-height: auto;
    max-height: 220px;
  }

  .resWrap {
    width: 100%;
    margin: 10px;
    border-radius: 10px;
    padding: 0 !important;
    overflow: hidden;
  }

  .resWrap h1 {
    font-size: 1.4rem;
    margin: 20px 0 10px 0;
  }

  .resWrap .stage {
    padding: 0;
  }

  .resWrap .folder-wrap svg {
    font-size: 40px;
  }

  .folder .folderTitle {
    text-align: left;
    padding-left: 20px;
  }

  [dir="rtl"] .folder .folderTitle {
    flex: 1 1;
    text-align: right;
    padding-left: 0px;
    padding-right: 20px;
  }

  [dir="rtl"] ol,
  [dir="rtl"] ul {
    padding-right: 2rem;
    padding-left: 0rem !important;
  }

  .breadcrumbWrapper {
    padding: 20px !important;
  }

  .slick-prev {
    right: -15px !important;
  }

  .slick-next {
    right: -15px !important;
  }

  .groupabout .div {
    padding: 0 20px;
  }

  .mobileWidth {
    width: 95% !important;
    padding: 10px !important;
    font-size: 0.8rem !important;
  }

  .mobileWidth.p-4 {
    padding: 10px !important;
  }

  button,
  input,
  optgroup,
  select,
  textarea,
  .error,
  .mobileWidth span.text-sm,
  strong {
    font-size: 0.8rem !important;
  }

  .tabletRow div:last-child {
    padding: 0 0.5rem 0.5rem !important;
  }

  /* .m-steps{
  margin-bottom: 0 !important;
} */
  .m-steps>div {
    padding: 0 5px;
  }

  .userSummary div:last-child {
    padding: 0 !important;
  }

  .formSettings div.pInfo {
    padding-top: 0 !important;
  }

  .radioOpt {
    padding: 0 10px;
  }

  .questionRadio.py-4 {
    padding-top: 0 !important;
  }

  .regEntity .md\:w-1\/3 {
    width: 100%;
  }







}

/* 600 end here*/







@media only screen and (min-device-width: 1025px) {

  .navbar-expand-lg .navbar-nav .nav-item {
    border-left: 1px solid #fbfbfb;
    position: relative;
  }

  [dir="rtl"] .navbar-expand-lg .navbar-nav .nav-item {
    border-right: 1px solid #fbfbfb;
    border-left: 0;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding: 4px 30px !important;
  }

  .aboutaward,
  .groupabout {
    height: auto;
  }

  .mask-group-icon {
    float: right;
    position: relative;
  }

  [dir="rtl"] .mask-group-icon {
    float: left;
  }

  .wrapper5 {
    position: relative;
    width: auto;
    height: auto;
    display: inline;
  }

  .div133 {
    display: inline;
  }

  .mask-group-parent {
    height: auto;
  }

  .carousel-indicators [data-bs-target] {
    border-top: 6px solid transparent !important;
  }

  .aboutaward {
    gap: 40px;
  }

  .slick-prev,
  .slick-next {
    width: 35px;
  }

  /* [dir='rtl'] .slick-next {
    right: auto !important;
    left: -45px !important;
    transform: rotate(-180deg) !important;
  }

  [dir='rtl'] .slick-prev {
    left: auto !important;
    right: -35px !important;
    transform: rotate(180deg) !important;
  }

  [dir='ltr'] .slick-next {
    left: auto !important;
    right: -45px !important;
    transform: rotate(-180deg) !important;
  }

  [dir='ltr'] .slick-prev {
    right: auto !important;
    left: -45px !important;
    transform: rotate(180deg) !important;
   
  } */

  /* [dir='ltr'] .slick-prev {
    right: -35px !important;
    left: auto !important;
  }

  .slick-next {
    right: -35px

  } */

}
