.carousel-inner {
  max-height: 600px;
}

.carousel .slide {
  max-height: 600px;
}

.w-4\/5 {
  max-width: 75rem !important;
}

.w-3\/5 {
  max-width: 50rem !important;
}

/* .carousel-indicators {
  bottom: 60px !important;
} */

.carousel-indicators button {
  border-radius: 50%;
  height: 15px !important;
  width: 15px !important;
}

.activeRoute {
  background-color: #1890c9;
}

body {
  background-image: url(./images/bg.png);
  background-repeat: repeat;
  font-family: "Cairo", sans-serif !important;
}
#SmallScreenMainDiv
{
  display: none;
}
@media only screen and (max-width: 600px) {
  .carousel-inner {
    max-height: 400px !important;
  }
  #AlertTextDiv{
    font-size: 12px;
    line-height: inherit;
    padding: 10px;
    text-align: left;
  }

  [dir="rtl"] #AlertTextDiv{
        text-align: right;
  }

  .carousel-indicators{
    display: none !important;
  }
  .w-3\/5 {
    width: 100% !important;
  }
  .hhImg{
    max-width: 60%;
    margin: 0 auto;
  }
  .socialArea{
    flex-direction: column !important;
  }
  .socialArea .socialIcons{
    width: 100%;
    padding-top: 20px;
  }
  .cW{
    text-align: center !important;
  }
  .btnWrap{
    display: flex !important;
  }
  .photos{
    flex-direction: column;
  }
  .photos img{
    max-width: 100%;
    height: auto;
  }
}
.carousel-item img{
  min-height: 130px;
}
.carousel-caption {
  text-align: right !important;
  margin-bottom: 35px;
}
.h-500 {
  height: 500px;
}
.carousel-indicators .active {
  background: #1053a2 !important;
  height: 0.1px !important;
}

.carousel-indicators button {
  background: white !important;
  opacity: 0.8 !important;
  height: 0.1px !important;
}
.h-1px {
  height: 1px;
}
.react-datepicker-wrapper {
  border-radius: 0.25rem !important;
  border: 1px solid #dee2e6 !important;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  outline: none;
  display: block !important;
}
.react-datepicker-wrapper input{
  width: 100%;
  outline: none;
}

.react-datepicker-ignore-onclickoutside {
  outline: none;
}
.react-tel-input .form-control{
  width: 100% !important;
  height: 45px;
}
.react-tel-input .special-label{
  display: none !important;
}

.hhImg{
  height: auto;
  max-width: 90%;
  max-height: 250px;
}

.btnWrap{
  display: inline-block;
  margin-top: 1rem;
}
.btnWrap a{
  text-decoration: none;
}
.socialIcons a{
  margin: 0 5px;
}
.textFont{
  font-size: 12px;
  font-weight: bold;
}

/* Add these styles to your main stylesheet or component-specific styles */

.folder-container {
  margin-bottom: 20px;
}

.slider-image {
  margin: 0 10px;
  text-align: center;
}

.lightbox-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 80%;
  max-height: 80%;
}

.lightbox-overlay {
  background: rgba(0, 0, 0, 0.5);
}
/*------------------------------------*/
.stage{
  display: flex;
  justify-content: flex-start;
  padding: 20px;
  flex-direction: column;
}
.folder-wrap{
  border: 1px solid #d9d9d9;
  padding: 20px;
  border-radius: 10px;
  background: #fff;
  margin: 10px;
  box-shadow: 0 0 5px #ccc;
}
.current-level{
  box-shadow: 0 0 10px #b9883d;
  border: 1px solid #b9883d;
}
/* .folder-wrap ul{
  border: 1px solid #d9d9d9;
    padding: 20px;
    text-align: right;
    border-radius: 10px;
    background: #fff;
    margin: 10px;
    box-shadow: 0 0 5px #ccc;
} */
.folder-wrap svg{
  font-size: 80px;
  color: #b9883d;
}
.slick-slider{
  margin: 0 auto;
  width: 90%;
  max-width: 1050px;
}
.SilderImageDemo
{
max-height: 300px;
}
.slick-slide img{
  margin: 0 auto;
}
.slick-track{
  text-align: center;
}
.number {
  height: 44px;
}
.react-player video {
  position: relative;
  left: 0;
  top: 0;
  transform: none;
}
.react-player {
margin:0 auto;
}
.appearance-none{
  height: 50px;
}
.small-images {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.small-image {
  margin: 0 5px;
  cursor: pointer;
}

.small-image img {
  width: 50px; /* Adjust the size as needed */
  height: 50px; /* Adjust the size as needed */
  border: 2px solid transparent;
}

.small-image.active img {
  border-color: #007bff; /* Highlight color for the active small image */
}
.video-card{
  padding: 8px;
}
[dir=rtl] .accordion-button::after{
  margin-right: auto;
  margin-left: 0;
}
.text-sub-main-blue{
  color: #0d6efd;
  text-align: center;
}
.questionRadio{
  display: flex;
    align-items: flex-start;
    flex-direction: column;

}
.quesRadioButtons{
  display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    width: 100%;
}

.quesRadioButtons button{
  min-width: 100px;
}
.quesRadioButtons button:first-child{
  margin-right: 20px;
}
[dir=rtl] .quesRadioButtons button:first-child{
  margin-left: 20px;
}

[dir=rtl] .form-check .form-check-input {
  float: right;
  margin-right: -1.5em;
}

[dir=rtl] .radioWrapper{
  margin-right: 22px;
}
.badge-warning {
  color: #212529;
  background-color: #fff1c6;
  padding-right: .6em;
  padding-left: .6em;
  border-radius: 10rem;
  display: inline-block;
  padding: .25em .4em;
  font-size: 75%;
  font-weight: 700;
  text-align: center;
  vertical-align: baseline;
  border-radius: .25rem;
}
.text-main-blue{
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  color: #b9883d;
  margin-bottom: 20px;
}
/*new slider home page style*/
.news-slider {
  width: 80%;
  margin: 0 auto;
  padding: 20px 0;
}

.news-slide {
  position: relative;
  text-align: center;
}

.news-image {
  width: 100%;
  height: auto;
  /*border-radius: 10px;*/
}

.news-content {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  padding: 10px;
  border-radius: 10px;
}

.news-content h2 {
  margin: 0;
  font-size: 24px;
}

.news-content p {
  font-size: 16px;
  margin-top: 5px;
}
.news-item a {
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  justify-content: center;
  text-align: center;
  margin: 10px;
  text-decoration: none;
}

.news-image {
  height: auto;
  margin: 0 auto;
  min-height: 398px;
  border: 1px solid #ccc;
}

.news-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.news-description {
  font-size: 1rem;
  color: #ffffff;
}
/**news listing***/
.news-listing {
  padding: 20px;
}

.news-item {
    flex: 1 1 calc(50% - 70px);
    align-items: flex-start;
    overflow: hidden;
}

.news-item h3 {
  font-size: 1.5rem;
  color: #333;
}

.news-item p {
  font-size: 1rem;
  color: #666;
}
.bg-main-gold {
  --tw-bg-opacity: 1;
  background-color: #c48a30;
  text-align: left;
  font-size: var(--h3-size);
  color: var(--color-white1);

}
.hamburger-wrapper{
  background-color: #C48A30;
  padding: 8px;
  position: relative;
}
.hamburger-menu {
    width: 36px;
    height: 26px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    background-color: #C48A30;
}

.hamburger-menu .line {
    width: 100%;
    height: 4px;
    background-color: #225043;
    transition: all 0.3s ease;
}

.hamburger-menu.open .line:nth-child(1) {
  transform: rotate(45deg) translate(10px, 10px);
}

.hamburger-menu.open .line:nth-child(2) {
  opacity: 0;
}

.hamburger-menu.open .line:nth-child(3) {
  transform: rotate(-45deg) translate(6px, -6px);
}

[dir="rtl"] .text-start{
  text-align: right !important; 
}
.div30{
  height: auto;
}